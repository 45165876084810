import { IModalBasicComponentModalProps, ModalBasicComponent } from '@app/components/modal/Modal'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { Button, Card, Label, PageSection, PageSectionVariants, Spinner, Text, TextContent } from '@patternfly/react-core'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { AxiosResponse } from 'axios'
import React from 'react'
import { LLMDetails } from './Modals/LlmDetails'

interface IPayload {
  models: Model[]
}

interface Model {
  name: string
  modified_at: Date
  size: number
  digest: string
  details: Details
}

interface Details {
  format: string
  family: string
  families: null
  parameter_size: string
  quantization_level: string
}

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const defaultModalProps: IModalBasicComponentModalProps = {
  component: DefaultModalComponent,
  description: '',
  title: '',
  data: {},
}

const LLMIndex: React.FunctionComponent = () => {
  const api = useAxios()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [models, setModels] = React.useState<Model[]>([])

  React.useEffect(() => {
    const abortController = new AbortController()

    setLoading(true)

    api.current
      ?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.ListLLMModels,
        data: {},
      })
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        if (response && response.data && response.data.result) {
          setModels(response.data.result['models'] as Model[])
          setLoading(false)
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  const columnNames = {
    id: 'ID',
    name: 'Name',
    lastModified: 'Last modified',
    family: 'Family',
    status: 'Status',
    parameterSize: 'Parameter size',
  }

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const openModal = (name: string) => {
    setIsModalOpen(!isModalOpen)
    defaultModalProps.title = `LLM model ${name} details`
    defaultModalProps.description = ''
    defaultModalProps.component = LLMDetails
    defaultModalProps.data = { name }
    setModalProps(defaultModalProps)
  }
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <React.Fragment>
      <ModalBasicComponent isModalOpen={isModalOpen} handleModalToggle={toggleModal} modalProps={modalProps} toggleModalProps={() => {}} key={null} type={''} props={undefined} />
      <PageSection variant={PageSectionVariants.light}>
        <TextContent>
          <Text component="h1">Large Language Models (LLM)</Text>
          <Text component="p">Description...</Text>
        </TextContent>
      </PageSection>
      <PageSection isFilled>
        <Card>
          {loading && <Spinner className="pf-u-m-xl" size="lg" />}
          {!loading && (
            <TableComposable className="vertical-align-middle" variant={'compact'} borders={true} isStriped>
              <Thead>
                <Tr>
                  <Th>{columnNames.id}</Th>
                  <Th>{columnNames.name}</Th>
                  <Th>{columnNames.lastModified}</Th>
                  <Th>{columnNames.family}</Th>
                  <Th>{columnNames.status}</Th>
                  <Th>{columnNames.parameterSize}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {models.map((model, index) => (
                  <Tr key={index}>
                    <Td dataLabel={columnNames.id}>{index}</Td>
                    <Td dataLabel={columnNames.name}>
                      <Button variant="link" isInline onClick={() => openModal(model.name)}>
                        {model.name}
                      </Button>
                    </Td>
                    <Td dataLabel={columnNames.lastModified}>{model.modified_at.toString()}</Td>
                    <Td dataLabel={columnNames.family}>{model.details.family}</Td>
                    <Td dataLabel={columnNames.status}>
                      <Label color="green" isCompact>
                        Operational
                      </Label>
                    </Td>
                    <Td dataLabel={columnNames.parameterSize}>{model.details.parameter_size}</Td>
                  </Tr>
                ))}
              </Tbody>
            </TableComposable>
          )}
        </Card>
      </PageSection>
    </React.Fragment>
  )
}

export { LLMIndex }
