import {
  Chart,
  ChartAxis,
  ChartAxisProps,
  ChartBar,
  ChartBarProps,
  ChartLegend,
  ChartLegendProps,
  ChartPie,
  ChartPieProps,
  ChartProps,
  ChartVoronoiContainer,
} from '@patternfly/react-charts'
import { Card, CardBody, CardTitle, DescriptionList, DescriptionListDescription, DescriptionListGroup, DescriptionListTerm, Gallery, Title } from '@patternfly/react-core'
import React from 'react'

interface Props {
  chartData: Record<string, unknown>[]
}

const component: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <React.Fragment>
      <Gallery hasGutter minWidths={{ xl: '420px' }}>
        {props.chartData.map(
          (chart, index) =>
            chart['visible'] === true && (
              <Card isCompact isFullHeight key={index} style={{ minHeight: '340px' }}>
                <CardTitle>
                  <Title headingLevel="h4" size="md">
                    {chart['name'] as string}
                  </Title>
                </CardTitle>
                <CardBody isFilled>
                  {(chart['type'] as string) === 'pie' && (
                    <ChartPie
                      labels={({ datum }) => `${datum.x}: ${datum.y}`}
                      {...(chart.chartPieProps as ChartPieProps)}
                      legendComponent={
                        <ChartLegend x={0} y={250} symbolSpacer={5} gutter={10} rowGutter={0} padding={0} {...(chart.chartLegendProps as ChartLegendProps)}></ChartLegend>
                      }
                    />
                  )}

                  {(chart['type'] as string) === 'bar' && (
                    <Chart containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.y}`} constrainToVisibleArea />} {...(chart.chartProps as ChartProps)}>
                      <ChartAxis {...(chart.chartAxisYProps as ChartAxisProps)} />
                      <ChartAxis {...(chart.chartAxisXProps as ChartAxisProps)} />
                      <ChartBar {...(chart.chartBarProps as ChartBarProps)} />
                    </Chart>
                  )}

                  {(chart['type'] as string) === 'stats' && (
                    <React.Fragment>
                      <DescriptionList isHorizontal isCompact termWidth="calc(100% - 50px)" className="pf-u-mt-lg">
                        {(chart['data'] as []).map((row, index) => (
                          <DescriptionListGroup key={index}>
                            <DescriptionListTerm>{row['name']}</DescriptionListTerm>
                            <DescriptionListDescription>{row['value']}</DescriptionListDescription>
                          </DescriptionListGroup>
                        ))}
                      </DescriptionList>
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>
            )
        )}
      </Gallery>
    </React.Fragment>
  )
}

const UserAnalysisCharts = component

export { UserAnalysisCharts }
