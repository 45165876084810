import React from 'react'
import ReactDOM from 'react-dom'
import App from '@app/index'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './keycloak'
import Moment from 'react-moment'
import 'moment-timezone'

Moment.globalLocale = 'en'
Moment.globalTimezone = 'Europe/Copenhagen'
Moment.globalFormat = 'YYYY-MM-DD HH:mm:ss'

const eventLogger = (event: unknown, error: unknown) => {
  console.log('onKeycloakEvent', event, error)
}

const tokenLogger = (tokens: unknown) => {
  console.log('onKeycloakTokens', tokens)
}
// Full option list and impl.: https://www.keycloak.org/docs/latest/securing_apps/index.html
const authOptions = {
  onLoad: 'login-required',
}

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider authClient={keycloak} onEvent={eventLogger} onTokens={tokenLogger} initOptions={authOptions}>
      <App />
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
)
