import { useAxios } from '@app/utils/useAxios'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import {
  ActionGroup,
  Button,
  CodeBlock,
  CodeBlockCode,
  Form,
  FormGroup,
  FormSection,
  FormSelect,
  FormSelectOption,
  Panel,
  PanelMain,
  PanelMainBody,
  TextArea,
} from '@patternfly/react-core'
import { useKeycloak } from '@react-keycloak/web'
import { AxiosResponse } from 'axios'
import React, { useRef } from 'react'

const initialModelSelectValue = { value: '', label: 'Select one' }

const LLMTest: React.FunctionComponent = () => {
  const api = useAxios()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [prompt, setPrompt] = React.useState<string>('')
  const [output, setOutput] = React.useState<string>('')
  const outputRef = useRef<string>('')
  const [model, setModel] = React.useState('')
  const [models, setModels] = React.useState([initialModelSelectValue])
  const { keycloak, initialized } = useKeycloak()
  const kcToken = keycloak?.token ?? ''

  React.useEffect(() => {
    const abortController = new AbortController()

    api.current
      ?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.ListLLMModels,
        data: {},
      })
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        if (response && response.data && response.data.result) {
          const options = response.data.result['models'].map((p) => {
            return {
              value: p.name,
              label: p.name,
            }
          })
          setModels([initialModelSelectValue, ...options])
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })

    return () => {
      abortController.abort()
    }
  }, [])

  const handleModelChange = (value: string, _event: React.FormEvent<HTMLSelectElement>) => {
    setModel(value)
  }

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!model) {
      alert('Select model first')
      return
    }

    setLoading(true)

    const url =
      process.env.NODE_ENV === 'production'
        ? 'https://lekrzhvcudlblyf4alqdhyb6zq0yqfvb.lambda-url.eu-central-1.on.aws/'
        : 'https://rnjzzlzsxbkashmrlgmw4c3j240bcfux.lambda-url.eu-central-1.on.aws/'
    const headers = new Headers({
      Authorization: initialized ? `Bearer ${kcToken}` : '',
    })
    const body = {
      model,
      prompt,
    }
    const response = await fetch(url, { method: 'POST', body: JSON.stringify(body), headers })
    const reader = response?.body?.getReader()

    if (reader) {
      while (true) {
        const { done, value } = await reader.read()

        if (done) {
          setLoading(false)
          outputRef.current = ''

          return
        }

        const result = new TextDecoder().decode(value)
        outputRef.current = outputRef.current + result
        setOutput(outputRef.current)
      }
    }
  }

  return (
    <React.Fragment>
      <Panel>
        <PanelMain>
          <PanelMainBody>
            <Form isHorizontal isWidthLimited onSubmit={submit} className="draft-order-form">
              <FormSection title="Input" titleElement="h2">
                <FormGroup label="Model" isRequired fieldId="horizontal-form-title">
                  <FormSelect value={model} onChange={handleModelChange} id="horizontal-form-title" name="horizontal-form-title">
                    {models.map((option, index) => (
                      <FormSelectOption key={index} value={option.value} label={option.label} />
                    ))}
                  </FormSelect>
                </FormGroup>
                <FormGroup label="Prompt" isRequired fieldId="txt-prompt">
                  <TextArea id="txt-prompt" rows={6} onChange={(value) => setPrompt(value)}></TextArea>
                </FormGroup>
              </FormSection>
              <ActionGroup className="pf-u-mt-0">
                <Button variant="primary" type="submit" isLoading={loading} isDisabled={loading}>
                  Submit
                </Button>
              </ActionGroup>
              {output.length > 0 && (
                <FormSection title="Output">
                  <CodeBlock>
                    <CodeBlockCode>
                      <div dangerouslySetInnerHTML={{ __html: output }}></div>
                    </CodeBlockCode>
                  </CodeBlock>
                </FormSection>
              )}
            </Form>
          </PanelMainBody>
        </PanelMain>
      </Panel>
    </React.Fragment>
  )
}

export { LLMTest }
