import React from 'react'
import { PageSection, Gallery, GalleryItem, Card, CardTitle, CardBody } from '@patternfly/react-core'
import { useHistory } from 'react-router-dom'

const DashboardHome: React.FunctionComponent = () => {
  const history = useHistory()

  return (
    <React.Fragment>
      <PageSection type="default">
        <Gallery
          hasGutter
          minWidths={{
            md: '300px',
          }}
        >
          <GalleryItem>
            <Card isFullHeight onClick={() => history.push('/model/bayesian')} hasSelectableInput isSelectableRaised>
              <CardTitle>Model management</CardTitle>
              <CardBody>Create, edit or delete recommendation and insights models</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card isFullHeight onClick={() => history.push('/product/main')} hasSelectableInput isSelectableRaised>
              <CardTitle>Product management</CardTitle>
              <CardBody>Product & Ingredient management</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card isFullHeight onClick={() => history.push('/insights/profile-analysis')} hasSelectableInput isSelectableRaised>
              <CardTitle>Insights</CardTitle>
              <CardBody>Access data and insights</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card isFullHeight onClick={() => history.push('/data')} hasSelectableInput isSelectableRaised>
              <CardTitle>Data management</CardTitle>
              <CardBody>Manage model input and training data</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card isFullHeight onClick={() => history.push('/customer')} hasSelectableInput isSelectableRaised>
              <CardTitle>User management</CardTitle>
              <CardBody>Manage customer relationship and data</CardBody>
            </Card>
          </GalleryItem>
        </Gallery>
      </PageSection>
    </React.Fragment>
  )
}

export { DashboardHome }
