import { useAxios } from '@app/utils/useAxios'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { Button, Card, PageSection, PageSectionVariants, Progress, ProgressMeasureLocation, ProgressVariant, Spinner, Text, TextContent } from '@patternfly/react-core'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { AxiosResponse } from 'axios'
import React from 'react'
import { useHistory } from 'react-router-dom'

const columnNames = {
  inciName: 'INCI name',
  posEvalCount: 'Evaluations positive',
  totalEvalCount: 'Evaluations total',
  successRate: 'Success rate',
}

interface Ingredient {
  skincareIngredientId: string
  inciName: string
  posEvalCount: string
  totalEvalCount: string
  successRate: string
}

const IngredientAnalysisIndex: React.FunctionComponent = () => {
  const api = useAxios()
  const history = useHistory()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [rows, setRows] = React.useState<Ingredient[]>([])

  React.useEffect(() => {
    const abortController = new AbortController()

    setLoading(true)

    api.current
      ?.post(BackofficeAPIConfig.Domains.Insights, {
        action: BackofficeApiActions.GetIngredientInsights,
        data: {},
      })
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        if (response && response.data && response.data.result) {
          setRows(response.data.result as Ingredient[])
          setLoading(false)
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <React.Fragment>
      <PageSection variant={PageSectionVariants.light}>
        <TextContent>
          <Text component="h1">Ingredient analysis</Text>
          <Text component="p">Access insights and reports</Text>
        </TextContent>
      </PageSection>
      <PageSection isFilled>
        <Card>
          {loading && <Spinner className="pf-u-m-xl" size="lg" />}
          {!loading && (
            <TableComposable aria-label="Selectable table" variant={'compact'} borders={true} isStriped>
              <Thead>
                <Tr>
                  <Th width={40}>{columnNames.inciName}</Th>
                  <Th width={10}>{columnNames.posEvalCount}</Th>
                  <Th width={10}>{columnNames.totalEvalCount}</Th>
                  <Th width={40}>{columnNames.successRate}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {rows.map((row, index) => (
                  <Tr key={index}>
                    <Td dataLabel={columnNames.inciName}>
                      <Button variant="link" isInline onClick={() => history.push(`/product/ingredients/read/${row.skincareIngredientId}`)}>
                        {row.inciName}
                      </Button>
                    </Td>
                    <Td dataLabel={columnNames.posEvalCount}>{row.posEvalCount}</Td>
                    <Td dataLabel={columnNames.totalEvalCount}>{row.totalEvalCount}</Td>
                    <Td dataLabel={columnNames.successRate}>
                      <Progress value={parseFloat(row.successRate) * 100} label={row.successRate} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </TableComposable>
          )}
        </Card>
      </PageSection>
    </React.Fragment>
  )
}

export { IngredientAnalysisIndex }
