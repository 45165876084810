export const APIBase = process.env.NODE_ENV === 'production' ? 'https://services.noie.com/production' : 'http://localhost:4000'

export const BackofficeAPIConfig = {
  BaseURL: `${APIBase}/backoffice`,
  MediaURL: `${APIBase}/media`,
  VersaURL: `${APIBase}/versa`,
  Domains: {
    Customer: '/customer',
    Data: '/data',
    WhatsApp: '/whatsapp',
    Employee: '/employee',
    Product: '/product',
    Model: '/model',
    MiniModel: '/mini_model',
    Insights: '/insights',
  },
}
