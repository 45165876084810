import { Button, Text, TextInput } from '@patternfly/react-core'
import { DatabaseIcon, PlusIcon, Remove2Icon, SaveIcon, SortAmountDownIcon, SyncAltIcon } from '@patternfly/react-icons'
import { Toolbar, ToolbarItem, ToolbarContent, ToolbarFilter, ToolbarGroup, Select, SelectOption, SelectVariant } from '@patternfly/react-core'
import React from 'react'
import { cloneDeep } from 'lodash'
import { Dropdown, DropdownItem, DropdownList } from '@patternfly/react-core/next'
import { MenuToggle } from '@patternfly/react-core'
import { IModalBasicComponentModalProps, ModalBasicComponent } from '../modal/Modal'
import { ChartsOrderComponent } from './Modals/ChartsOrderComponent'
import { SaveViewComponent } from './Modals/SaveViewComponent'
import { LoadViewComponent } from './Modals/LoadViewComponent'

export interface IFilterBase {
  name: string
  category: string
  type: 'select' | 'interval'
  isActive: boolean
  isDefault: boolean
  source: Record<string, unknown>
}

export interface ISelectFilter extends IFilterBase {
  isExpanded: boolean
  options: ISelectFilterOption[]
}

export interface ISelectFilterOption {
  key: string
  value: string
  isSelected: boolean
}

export interface IIntervalFilter extends IFilterBase {
  min: string
  max: string
  valueMin: string
  valueMax: string
}

export type Filter = ISelectFilter | IIntervalFilter

interface UserAnalysisFiltersProps {
  config: Record<string, unknown>
  filters: Filter[]
  chartData: Record<string, unknown>[]
  onSelect?: (filters: Filter[]) => void
  onReload?: (filters: Filter[]) => void
  onReorderCharts?: (chartData: Record<string, unknown>[]) => void
  onSaveView?: (viewName: string, filters: Filter[]) => void
  onLoadView?: (loadView: Record<string, any>) => void
}

interface UserAnalysisFiltersHandle {
  clear: () => void
  set: (items: Filter[]) => void
}

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const component: React.ForwardRefRenderFunction<UserAnalysisFiltersHandle, UserAnalysisFiltersProps> = (
  props: UserAnalysisFiltersProps,
  ref: React.ForwardedRef<UserAnalysisFiltersHandle>
) => {
  const [filters, setFilters] = React.useState<Filter[]>(props.filters)

  React.useEffect(() => {
    setFilters(props.filters)
  }, [props.filters])

  React.useEffect(() => {
    if (typeof props.onSelect === 'function') {
      props.onSelect(filters)
    }
  }, [filters])

  const onOptionChipDelete = (type: string, id: string, filterIndex: number) => {
    const nextFilters = filters.map((filter, index) => {
      if (filterIndex === index) {
        return {
          ...filter,
          options: (filter as ISelectFilter).options.map((p) => {
            if (p.value === id) {
              return {
                ...p,
                isSelected: false,
              }
            } else {
              return p
            }
          }),
        }
      } else {
        return filter
      }
    })

    setFilters(nextFilters)
  }

  const onOptionChipGroupDelete = (filterIndex: number) => {
    const nextFilters = filters.map((filter, index) => {
      if (filterIndex === index) {
        return {
          ...filter,
          options: (filter as ISelectFilter).options.map((p) => {
            return { ...p, isSelected: false }
          }),
        }
      } else {
        return filter
      }
    })

    setFilters(nextFilters)
  }

  const onOptionToggle = (isExpanded: boolean, filterIndex: number) => {
    const nextFilters = filters.map((filter, index) => {
      if (filterIndex === index) {
        return {
          ...filter,
          isExpanded,
        }
      } else {
        return filter
      }
    })

    setFilters(nextFilters)
  }

  const onOptionSelect = (event: React.MouseEvent | React.ChangeEvent, selection: string, filterIndex: number) => {
    const filtersCopy = cloneDeep(filters)
    const selectedFilter = filtersCopy[filterIndex] as ISelectFilter
    const option = selectedFilter.options.find((p) => p.value === selection)

    if (option) {
      option.isSelected = (event.target as any).checked as boolean

      setFilters(filtersCopy)
    }
  }

  const onIntervalValueChange = (value: string, type: 'valueMin' | 'valueMax', filterIndex: number) => {
    const nextFilters = cloneDeep(filters)
    const selectedFilter = nextFilters[filterIndex] as IIntervalFilter
    selectedFilter[type] = value
    setFilters(nextFilters)
  }

  const onFilterDeactivate = (filterIndex: number) => {
    const nextFilters = filters.map((filter, index) => {
      if (filterIndex === index) {
        if (filter.type === 'select') {
          return {
            ...filter,
            isActive: false,
            options: (filter as ISelectFilter).options.map((p) => {
              return { ...p, isSelected: false }
            }),
          }
        } else if (filter.type === 'interval') {
          return {
            ...filter,
            isActive: false,
          }
        } else {
          return filter
        }
      } else {
        return filter
      }
    })

    setFilters(nextFilters)
  }

  const onFilterActivate = (filterIndex: number, e: Event) => {
    e.preventDefault()

    const nextFilters = filters.map((filter, index) => {
      if (filterIndex === index) {
        return {
          ...filter,
          isActive: true,
        }
      } else {
        return filter
      }
    })

    const nextFilterIndex = nextFilters.filter((p) => p.isActive).length - 1

    nextFilters.splice(nextFilterIndex, 0, nextFilters.splice(filterIndex, 1)[0])

    setFilters(nextFilters)
  }

  const [isFilterOptionsOpen, setIsFilterOptionsOpen] = React.useState(false)

  const onFilterOptionsToggleClick = () => {
    setIsFilterOptionsOpen(!isFilterOptionsOpen)
  }

  const onFilterOptionsSelect = (_event: React.MouseEvent<Element, MouseEvent> | undefined, itemId: string | number | undefined) => {
    setIsFilterOptionsOpen(false)
  }

  const onReloadClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    if (typeof props.onReload === 'function') {
      props.onReload(filters)
    }
  }

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const toggleModal = (id: string) => {
    setIsModalOpen(!isModalOpen)

    if (id === chartsOrderModalId && typeof props.onReorderCharts === 'function') {
      const chartData = modalProps.data['chartData'] as Record<string, unknown>[]
      props.onReorderCharts(chartData)
    }

    if (id === saveViewModalId && typeof props.onSaveView === 'function' && typeof modalProps.data['viewName'] === 'string') {
      props.onSaveView(modalProps.data['viewName'] as string, filters)
    }

    if (id === loadViewModalId && typeof props.onLoadView === 'function' && modalProps.data['loadView']) {
      props.onLoadView(modalProps.data['loadView'])
    }
  }

  const chartsOrderModalId = 'charts-order-modal'
  const openReorderChartsModal = () => {
    defaultModalProps.title = 'Widgets'
    defaultModalProps.description = `Reorder and choose which widgets to show`
    defaultModalProps.component = ChartsOrderComponent
    defaultModalProps.data = { chartData: props.chartData }
    defaultModalProps.id = chartsOrderModalId
    setIsModalOpen(!isModalOpen)
    setModalProps(defaultModalProps)
  }

  const saveViewModalId = 'save-view-modal'
  const openSaveViewModal = () => {
    defaultModalProps.title = 'Save view'
    defaultModalProps.description = `Save current view with filters and charts`
    defaultModalProps.component = SaveViewComponent
    defaultModalProps.data = {}
    defaultModalProps.id = saveViewModalId
    setIsModalOpen(!isModalOpen)
    setModalProps(defaultModalProps)
  }

  const loadViewModalId = 'load-view-modal'
  const openLoadViewModal = () => {
    defaultModalProps.title = 'Load view'
    defaultModalProps.description = `Load saved view`
    defaultModalProps.component = LoadViewComponent
    defaultModalProps.data = { config: props.config }
    defaultModalProps.id = loadViewModalId
    setIsModalOpen(!isModalOpen)
    setModalProps(defaultModalProps)
  }

  const toolbarItems = (
    <React.Fragment>
      <ToolbarGroup variant="filter-group" alignment={{ default: 'alignLeft' }} style={{ flexWrap: 'wrap', width: '85%' }}>
        {filters.map((filter, index) => (
          <React.Fragment key={index}>
            {filter.type === 'select' && (
              <ToolbarFilter
                key={`tf-select-${index}`}
                className="pf-u-display-flex pf-u-align-items-center pf-u-mb-md"
                variant="overflow-menu"
                chips={(filter as ISelectFilter).options
                  .filter((p) => p.isSelected)
                  .map((p) => {
                    return p.value
                  })}
                deleteChip={(category, chip) => onOptionChipDelete(category as string, chip as string, index)}
                deleteChipGroup={() => onOptionChipGroupDelete(index)}
                categoryName={filter.name}
                showToolbarItem={filter.isActive}
              >
                <Select
                  variant={SelectVariant.checkbox}
                  onToggle={(isExpanded: boolean) => onOptionToggle(isExpanded, index)}
                  onSelect={(event, selection) => onOptionSelect(event, selection as string, index)}
                  selections={(filter as ISelectFilter).options
                    .filter((p) => p.isSelected)
                    .map((p) => {
                      return p.value
                    })}
                  isOpen={(filter as ISelectFilter).isExpanded}
                  placeholderText={filter.name}
                >
                  {(filter as ISelectFilter).options.map((option) => (
                    <SelectOption key={option.key} value={option.value} isChecked={option.isSelected} />
                  ))}
                </Select>
                {!filter.isDefault && (
                  <Button variant="plain" onClick={() => onFilterDeactivate(index)}>
                    <Remove2Icon />
                  </Button>
                )}
              </ToolbarFilter>
            )}

            {filter.type === 'interval' && (
              <React.Fragment>
                <ToolbarFilter
                  key={`tf-interval-${index}`}
                  categoryName={filter.name}
                  variant="label"
                  className="pf-u-display-flex pf-u-align-items-center pf-u-flex-wrap pf-u-mb-md"
                  showToolbarItem={filter.isActive}
                  deleteChip={(category, chip) => onFilterDeactivate(index)}
                  chips={filter.isActive ? [`${(filter as IIntervalFilter).valueMin}:${(filter as IIntervalFilter).valueMax}`] : []}
                >
                  <Text className="pf-u-mr-md">{filter.name}</Text>

                  <TextInput
                    id={`from_${index}`}
                    style={{ width: '60px' }}
                    type="number"
                    placeholder="from"
                    value={(filter as IIntervalFilter).valueMin}
                    onChange={(value) => onIntervalValueChange(value, 'valueMin', index)}
                  ></TextInput>

                  <TextInput
                    id={`to_${index}`}
                    style={{ width: '60px' }}
                    type="number"
                    placeholder="to"
                    value={(filter as IIntervalFilter).valueMax}
                    onChange={(value) => onIntervalValueChange(value, 'valueMax', index)}
                  ></TextInput>

                  {!filter.isDefault && (
                    <Button variant="plain" onClick={() => onFilterDeactivate(index)}>
                      <Remove2Icon />
                    </Button>
                  )}
                </ToolbarFilter>
              </React.Fragment>
            )}
          </React.Fragment>
        ))}
        <ToolbarItem className="pf-u-mb-md">
          <Dropdown
            isScrollable={true}
            isOpen={isFilterOptionsOpen}
            onSelect={onFilterOptionsSelect}
            minWidth="380px"
            onOpenChange={(isOpen) => setIsFilterOptionsOpen(isOpen)}
            toggle={(toggleRef) => (
              <MenuToggle ref={toggleRef} aria-label="kebab dropdown toggle" variant="plain" onClick={onFilterOptionsToggleClick} isExpanded={isFilterOptionsOpen}>
                <PlusIcon />
              </MenuToggle>
            )}
          >
            <DropdownList>
              {filters.map((filter, index) => (
                <DropdownItem itemId={index} key={index} onClick={(e) => onFilterActivate(index, e)} isDisabled={filter.isActive}>
                  {filter.name}
                </DropdownItem>
              ))}
            </DropdownList>
          </Dropdown>
        </ToolbarItem>

        <ToolbarItem className="pf-u-mb-md">
          <Button variant="plain" onClick={(e) => onReloadClick(e)}>
            <SyncAltIcon></SyncAltIcon>
          </Button>
        </ToolbarItem>

        <ToolbarItem className="pf-u-mb-md">
          <Button variant="plain" onClick={() => openReorderChartsModal()} isInline>
            <SortAmountDownIcon></SortAmountDownIcon>
          </Button>
        </ToolbarItem>

        <ToolbarItem className="pf-u-mb-md">
          <Button variant="plain" onClick={() => openSaveViewModal()} isInline>
            <SaveIcon></SaveIcon>
          </Button>
        </ToolbarItem>

        <ToolbarItem className="pf-u-mb-md">
          <Button variant="plain" onClick={() => openLoadViewModal()} isInline>
            <DatabaseIcon></DatabaseIcon>
          </Button>
        </ToolbarItem>
      </ToolbarGroup>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <ModalBasicComponent isModalOpen={isModalOpen} handleModalToggle={toggleModal} modalProps={modalProps} toggleModalProps={() => {}} key={null} type={''} props={undefined} />
      <Toolbar
        collapseListedFiltersBreakpoint="md"
        clearFiltersButtonText=""
        inset={{
          default: 'insetNone',
        }}
        style={{ paddingBottom: 0 }}
      >
        <ToolbarContent style={{ marginBottom: '-20px' }}>{toolbarItems}</ToolbarContent>
      </Toolbar>
    </React.Fragment>
  )
}

const CustomFilters = React.memo(React.forwardRef(component))

export { CustomFilters }
