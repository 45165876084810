import { NavLink, useLocation, useHistory } from 'react-router-dom'
import {
  Nav,
  NavList,
  NavItem,
  NavExpandable,
  Page,
  PageHeader,
  PageSidebar,
  SkipToContent,
  PageHeaderTools,
  Icon,
  Tooltip,
  ApplicationLauncher,
  ApplicationLauncherItem,
} from '@patternfly/react-core'
import { routes, IAppRoute, IAppRouteGroup, AppRouteConfig } from '@app/routes/routes'
import logoWhite from '@app/assets/bgimages/NOIE_LOGO_WHITE.png'
import Kylie_Cosmetics_Logo from '@app/assets/bgimages/Kylie_Cosmetics_Logo.png'
import lancaster_logo from '@app/assets/bgimages/lancaster_logo.png'
import logo_skkn from '@app/assets/bgimages/logo_skkn.png'
import Orveda from '@app/assets/bgimages/Orveda.png'
import philosophylogo from '@app/assets/bgimages/philosophylogo.png'
import cotyLogo from '@app/assets/bgimages/COTY_original.png'
import * as React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { ShareSquareIcon } from '@patternfly/react-icons'

interface IAppLayout {
  children: React.ReactNode
}

const AppLayout: React.FunctionComponent<IAppLayout> = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = React.useState(true)
  const [isMobileView, setIsMobileView] = React.useState(true)
  const [isNavOpenMobile, setIsNavOpenMobile] = React.useState(false)

  const identity = useKeycloak()

  const onNavToggleMobile = () => {
    setIsNavOpenMobile(!isNavOpenMobile)
  }
  const onNavToggle = () => {
    setIsNavOpen(!isNavOpen)
  }
  const onPageResize = (props: { mobileView: boolean; windowSize: number }) => {
    setIsMobileView(props.mobileView)
  }

  function LogoImg() {
    const history = useHistory()
    const [isOpen, setIsOpen] = React.useState(false)
    const onToggle = (isOpen: boolean) => setIsOpen(isOpen)
    const onSelect = (_event: any) => setIsOpen((prevIsOpen) => !prevIsOpen)

    const appLauncherItems: React.ReactElement[] = [
      <ApplicationLauncherItem key="key_01" component="button" icon={<img src={Orveda}></img>} onClick={() => alert('Clicked')} style={{ minWidth: '240px' }}>
        Orveda
      </ApplicationLauncherItem>,
      <ApplicationLauncherItem key="key_02" component="button" icon={<img src={philosophylogo}></img>} onClick={() => alert('Clicked')} style={{ minWidth: '240px' }}>
        Philosophy
      </ApplicationLauncherItem>,
      <ApplicationLauncherItem key="key_03" component="button" icon={<img src={logo_skkn}></img>} onClick={() => alert('Clicked')} style={{ minWidth: '240px' }}>
        SKKN
      </ApplicationLauncherItem>,
      <ApplicationLauncherItem key="key_04" component="button" icon={<img src={Kylie_Cosmetics_Logo}></img>} onClick={() => alert('Clicked')} style={{ minWidth: '240px' }}>
        Kylie Skin
      </ApplicationLauncherItem>,
      <ApplicationLauncherItem key="key_05" component="button" icon={<img src={lancaster_logo}></img>} onClick={() => alert('Clicked')} style={{ minWidth: '240px' }}>
        Lancaster
      </ApplicationLauncherItem>,
    ]

    return (
      <React.Fragment>
        <img src={logoWhite} onClick={() => history.push('/')} alt="NØIE" />
        <ApplicationLauncher onSelect={onSelect} onToggle={onToggle} isOpen={isOpen} items={appLauncherItems} removeFindDomNode={true} />
      </React.Fragment>
    )
  }

  const HeaderTools = () => {
    const name = identity.keycloak.tokenParsed ? identity.keycloak.tokenParsed['given_name'] : ''
    const email = identity.keycloak.tokenParsed ? identity.keycloak.tokenParsed['email'] : ''
    const logoutUrl = identity.keycloak.createLogoutUrl({ redirectUri: document.URL })

    return (
      <PageHeaderTools>
        <span className="pf-u-display-inline-block pf-u-pr-md">
          {name} ({email})
        </span>
        <Tooltip content={'Logout'}>
          <a href={logoutUrl}>
            <ShareSquareIcon color="white"></ShareSquareIcon>
          </a>
        </Tooltip>
      </PageHeaderTools>
    )
  }

  const Header = <PageHeader logo={<LogoImg />} headerTools={<HeaderTools />} showNavToggle isNavOpen={isNavOpen} onNavToggle={isMobileView ? onNavToggleMobile : onNavToggle} />

  const location = useLocation()

  const renderNavItem = (route: IAppRoute, index: number) => (
    <NavItem key={`${route.label}-${index}`} id={`${route.label}-${index}`} isActive={route.path === location.pathname}>
      <NavLink exact={route.exact} to={route.path}>
        {route.label}
      </NavLink>
    </NavItem>
  )

  const renderNavGroup = (group: IAppRouteGroup, groupIndex: number) => (
    <NavExpandable
      key={`${group.label}-${groupIndex}`}
      id={`${group.label}-${groupIndex}`}
      title={group.label}
      isActive={group.routes.some((route) => route.path === location.pathname)}
    >
      {group.routes.map((route, idx) => route.label && renderNavItem(route, idx))}
    </NavExpandable>
  )

  const shouldRenderNavItem = (route: AppRouteConfig): boolean => {
    if (!route.label) return false

    const userRoles = identity.keycloak.tokenParsed?.realm_access?.roles ?? []
    const role = (route as IAppRoute).role ?? undefined

    if (role && !userRoles.includes(role)) {
      return false
    }

    return true
  }

  const Navigation = (
    <Nav id="nav-primary" theme="dark">
      <NavList id="nav-list">{routes.map((route, idx) => shouldRenderNavItem(route) && (!route.routes ? renderNavItem(route, idx) : renderNavGroup(route, idx)))}</NavList>
    </Nav>
  )

  const Sidebar = <PageSidebar theme="dark" nav={Navigation} isNavOpen={isMobileView ? isNavOpenMobile : isNavOpen} />

  const pageId = 'primary-app-container'

  const PageSkipToContent = (
    <SkipToContent
      onClick={(event) => {
        event.preventDefault()
        const primaryContentContainer = document.getElementById(pageId)
        primaryContentContainer && primaryContentContainer.focus()
      }}
      href={`#${pageId}`}
    >
      Skip to Content
    </SkipToContent>
  )
  return (
    <Page mainContainerId={pageId} header={Header} sidebar={Sidebar} onPageResize={onPageResize} skipToContent={PageSkipToContent}>
      {children}
    </Page>
  )
}

export { AppLayout }
