import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { CodeBlock, CodeBlockCode, Spinner } from '@patternfly/react-core'
import { AxiosResponse } from 'axios'
import React from 'react'

const LLMDetails: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [data, setData] = React.useState<Record<string, any> | undefined>(undefined)

  React.useEffect(() => {
    const abortController = new AbortController()

    setLoading(true)

    api.current
      ?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.GetLLMModelDetails,
        data: {
          name: props.data['name'],
        },
      })
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        if (response && response.data && response.data.result) {
          setData(response.data.result as Record<string, any>)
          setLoading(false)
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <React.Fragment>
      {loading && <Spinner />}
      {!loading && (
        <CodeBlock>
          <CodeBlockCode id="code-content">{JSON.stringify(data, null, 4)}</CodeBlockCode>
        </CodeBlock>
      )}
    </React.Fragment>
  )
}

export { LLMDetails }
