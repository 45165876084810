import React from 'react'
import { PageSection, TextContent, Text, PageSectionVariants, TabTitleText, Tabs, Tab, TabContent, TabContentBody } from '@patternfly/react-core'
import { ListDataSets } from './DataSets/ListDataSets'
import { useHistory, useParams } from 'react-router-dom'
import { ListEvaluations } from './Evaluations/ListEvaluations'
import { ListDataDefinitions } from './DataDefinitions/ListDataDefinitions'

const DataHome: React.FunctionComponent = () => {
  // fixed data
  const history = useHistory()
  const pathParams = useParams()
  const sections = ['main', 'evaluations', 'data_definitions']
  const { section } = pathParams as Record<string, string | undefined>

  const startTabKey = sections.includes(section || '') ? section : sections[0]

  // reactive data
  const [activeTabKey, setActiveTabKey] = React.useState(startTabKey)

  // Toggle currently active tab
  const handleTabClick = (tabKey: string) => {
    setActiveTabKey(tabKey)
    history.push(`/data/${tabKey}`)
  }

  return (
    <React.Fragment>
      <PageSection variant={PageSectionVariants.light}>
        <TextContent>
          <Text component="h1">Model data management</Text>
          <Text component="p">Overview and manage incoming data</Text>
        </TextContent>
      </PageSection>
      <PageSection type="tabs">
        <Tabs mountOnEnter activeKey={activeTabKey} onSelect={(_event, tabKey) => handleTabClick(String(tabKey))} usePageInsets id="nested-tabs-list" isBox>
          <Tab eventKey={sections[0]} title={<TabTitleText>Data Sets</TabTitleText>} tabContentId={`tabContent${0}`}>
            <TabContent id={`tabContent${0}`}>
              <TabContentBody hasPadding children={<ListDataSets></ListDataSets>}></TabContentBody>
            </TabContent>
          </Tab>
          <Tab eventKey={sections[1]} title={<TabTitleText>Evaluations</TabTitleText>} tabContentId={`tabContent${1}`}>
            <TabContent id={`tabContent${1}`}>
              <TabContentBody hasPadding children={<ListEvaluations></ListEvaluations>}></TabContentBody>
            </TabContent>
          </Tab>
          <Tab eventKey={sections[2]} title={<TabTitleText>Data Definitions</TabTitleText>} tabContentId={`tabContent${2}`}>
            <TabContent id={`tabContent${2}`}>
              <TabContentBody hasPadding children={<ListDataDefinitions></ListDataDefinitions>}></TabContentBody>
            </TabContent>
          </Tab>
        </Tabs>
      </PageSection>
    </React.Fragment>
  )
}

export { DataHome }
